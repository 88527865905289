<template>
  <v-container fluid v-if="isLoaded">
    <SchedulesCalender />
    <ActionButton bottom right fixed @click="$router.push({ name: 'AddSchedules' })" v-if="editor">
      <v-icon>mdi-calendar-plus</v-icon>
    </ActionButton>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import SchedulesCalender from '@/components/SchedulesCalender.vue';
import ActionButton from '@/components/ActionButton.vue';
import authMixin from '@/mixins/auth';

export default {
  name: 'CalendarView',
  components: {
    SchedulesCalender,
    ActionButton,
  },
  mixins: [authMixin],
  computed: {
    ...mapGetters('genesys/me', ['isLoaded']),
  },
};
</script>
