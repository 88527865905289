<template>
  <v-app>
    <app-bar @drawer="drawer = !drawer" />
    <navigation-drawer v-model="drawer" v-if="loggedIn" />
    <v-main>
      <router-view />
    </v-main>
    <notification-bar />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import AppBar from '@/components/AppBar';
import NavigationDrawer from '@/components/NavigationDrawer';
import NotificationBar from '@/components/NotificationBar';

export default {
  name: 'App',
  components: {
    AppBar,
    NotificationBar,
    NavigationDrawer,
  },
  data() {
    return {
      drawer: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['loggedIn']),
  },
};
</script>
