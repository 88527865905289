import kpn from '@/axios/kpn';

export default {
  addSchedules(schedules, deleteActiveSchedules) {
    const body = {
      deleteActiveSchedules,
      schedules,
    };
    return kpn.post('/scheduler/schedules', body);
  },
  searchSchedules(body) {
    return kpn.post(`/scheduler/schedules/search`, body);
  },
  deleteSchedule(id) {
    return kpn.delete(`/scheduler/schedules/${id}`);
  },
};
