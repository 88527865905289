import Vue from 'vue';
import VueRouter from 'vue-router';
import CallbackView from '@/views/CallbackView.vue';
import CalendarView from '@/views/CalendarView.vue';
import LoginView from '../views/LoginView.vue';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'Calendar' },
  },
  {
    path: '/callback',
    name: 'Callback',
    component: CallbackView,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: CalendarView,
    meta: { requiresAuth: true },
  },
  {
    path: '/import',
    name: 'Import',
    component: () => import(/* webpackChunkName: "import" */ '@/views/ImportView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/addschedules',
    name: 'AddSchedules',
    component: () => import(/* webpackChunkName: "addschedules" */ '@/views/AddSchedulesView.vue'),
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = store.getters['auth/loggedIn'];
  if (to.meta.requiresAuth) {
    if (!loggedIn) next({ name: 'login' });
    else next();
  } else {
    next();
  }
});

export default router;
