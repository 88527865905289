import axios from 'axios';
import store from '@/store';

const kpn = axios.create({
  baseURL: process.env.VUE_APP_KPN_API_URI,
});

kpn.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch('auth/logout');
    }
    return Promise.reject(error);
  }
);

export default kpn;
