import auth from '@/axios/auth';

export default {
  getAccessToken(code, redirectUri, app) {
    const body = {
      code,
      redirectUri,
      app,
    };
    return auth.post('/auth/token', body);
  },
  logout() {
    return auth.get('/auth/logout');
  },
};
