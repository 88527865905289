import GenesysService from '@/services/GenesysService.js';

export default {
  namespaced: true,
  state: {
    queues: [],
    selectedQueue: null,
    loading: false,
  },
  mutations: {
    SET_QUEUES(state, queues) {
      state.queues = queues;
    },
    SET_SELECTED_QUEUE(state, queue) {
      state.selectedQueue = queue;
    },
    SET_LOADING_STATUS(state, status) {
      state.loading = status;
    },
  },
  actions: {
    getQueues({ dispatch, commit }, name) {
      commit('SET_LOADING_STATUS', true);
      GenesysService.getQueues(name)
        .then((response) => commit('SET_QUEUES', response.data.entities))
        .catch((error) => {
          console.error(error.message);
          dispatch('notifications/addNotification', { message: 'Failed to get queues', type: 'error' }, { root: true });
        })
        .finally(() => commit('SET_LOADING_STATUS', false));
    },
  },
};
