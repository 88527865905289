import KpnService from '@/services/KpnService.js';

export default {
  namespaced: true,
  state: {
    validationErrors: [],
    scheduleErrors: [],
  },
  mutations: {
    SET_VALIDATION_ERRORS(state, errors) {
      state.validationErrors = errors;
    },
    SET_SCHEDULE_ERRORS(state, errors) {
      state.scheduleErrors = errors;
    },
  },
  actions: {
    importSchedules({ dispatch, commit }, { schedules, deleteActiveSchedules }) {
      commit('SET_VALIDATION_ERRORS', []);
      commit('SET_SCHEDULE_ERRORS', []);
      KpnService.addSchedules(schedules, deleteActiveSchedules)
        .then((response) => {
          commit('SET_VALIDATION_ERRORS', response.data.validateErrors);
          commit('SET_SCHEDULE_ERRORS', response.data.scheduleErrors);
          dispatch('notifications/addNotification', { message: 'Schedules imported successfully!!!!' }, { root: true });
        })
        .catch((error) => {
          dispatch('notifications/addNotification', { message: error.message, type: 'error' }, { root: true });
        });
    },
    reset({ commit }) {
      commit('SET_VALIDATION_ERRORS', []);
      commit('SET_SCHEDULE_ERRORS', []);
    },
  },
  getters: {
    hasValidationErrors: (state) => state.validationErrors.length > 0,
    hasScheduleErrors: (state) => state.scheduleErrors.length > 0,
  },
};
