<template>
  <div>
    <div class="d-flex align-center mb-2">
      <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">Today</v-btn>
      <v-btn fab text small color="grey darken-2" @click="prev"><v-icon small>mdi-chevron-left</v-icon></v-btn>
      <v-btn fab text small color="grey darken-2" class="mr-4" @click="next"><v-icon small>mdi-chevron-right</v-icon></v-btn>
      <v-toolbar-title class="hidden-sm-and-down" v-if="$refs.calendar">{{ $refs.calendar.title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <QueueSelection class="mr-4 hidden-xs-only" />
      <v-menu bottom right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
            <span>{{ typeToLabel[type] }}</span>
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="type = 'day'">
            <v-list-item-title>Day</v-list-item-title>
          </v-list-item>
          <v-list-item @click="type = 'week'">
            <v-list-item-title>Week</v-list-item-title>
          </v-list-item>
          <v-list-item @click="type = 'month'">
            <v-list-item-title>Month</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-sheet height="calc(100vh - 136px)">
      <v-calendar
        ref="calendar"
        v-model="focus"
        color="primary"
        :events="events"
        :type="type"
        :event-color="getColor"
        :interval-format="intervalFormat"
        :weekdays="weekdays"
        event-overlap-mode="column"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
        @change="updateRange"
        v-touch="{
          left: () => next(),
          right: () => prev(),
        }"
      ></v-calendar>
    </v-sheet>
    <v-menu
      v-model="selectedOpen"
      v-if="selectedEvent"
      :close-on-content-click="false"
      :activator="selectedElement"
      :nudge-bottom="8"
      :max-width="500"
      offset-y
      content-class="elevation-8"
    >
      <v-card tile>
        <v-card-title>{{ selectedEvent.name }}</v-card-title>
        <v-card-text>
          <div>
            <v-icon class="mr-2">mdi-calendar-start</v-icon><span>{{ selectedEvent.start.toLocaleString() }}</span>
          </div>
          <div class="mt-2">
            <v-icon class="mr-2">mdi-calendar-end</v-icon><span>{{ selectedEvent.end.toLocaleString() }}</span>
          </div>
          <div class="mt-2" v-if="selectedEvent.schedule.workgroups && selectedEvent.schedule.workgroups.length > 0">
            <v-icon class="mr-2">mdi-human-queue</v-icon><span>{{ selectedEvent.schedule.workgroups }}</span>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon small @click="handleDeleteClick(selectedEvent)" v-if="editor">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import QueueSelection from '@/components/QueueSelection';
import authMixin from '@/mixins/auth';

export default {
  name: 'SchedulesCalender',
  components: {
    QueueSelection,
  },
  mixins: [authMixin],
  data() {
    return {
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
      },
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      selectedEvent: null,
      selectedElement: null,
      selectedOpen: false,
      start: null,
      end: null,
    };
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  computed: {
    ...mapState('schedules', ['schedules']),
    ...mapState('genesys/queues', ['selectedQueue']),
    ...mapGetters('genesys/me', ['groupIds']),
    ...mapGetters('auth', ['config']),
    events() {
      return this.schedules.map((schedule) => {
        return {
          name: schedule.name || schedule.email,
          start: new Date(schedule.scheduleStartDate),
          end: new Date(schedule.scheduleEndDate),
          timed: true,
          schedule,
        };
      });
    },
  },
  watch: {
    selectedQueue: function () {
      this.searchSchedules();
    },
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = 'day';
    },
    setToday() {
      this.focus = '';
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    getColor(event) {
      switch (event.schedule.state) {
        case 'Active':
          return 'primary';
        case 'LoggedIn':
          return 'green';
        case 'LoggedInManual':
          return '#1B5E20';
        case 'LoggedOff':
          return 'grey';
        default:
          return 'red';
      }
    },
    updateRange({ start, end }) {
      this.start = new Date(start.year, start.month - 1, start.day, 0, 0, 0);
      this.end = new Date(end.year, end.month - 1, end.day, 23, 59, 59);
      this.searchSchedules();
    },
    intervalFormat(interval) {
      return interval.time;
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    handleDeleteClick(event) {
      this.$store.dispatch('schedules/deleteSchedule', event.schedule.id);
      this.selectedOpen = false;
    },
    searchSchedules() {
      const body = {
        start: this.start,
        end: this.end,
        workgroup: this.selectedQueue
          ? {
              name: this.selectedQueue.name,
              id: this.selectedQueue.id,
            }
          : undefined,
        groups: this.config.filterOnGroups ? this.groupIds.slice(0, 50) : undefined,
      };
      this.$store.dispatch('schedules/searchSchedules', body);
    },
  },
};
</script>
