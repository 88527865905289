<template>
  <v-navigation-drawer app :clipped="$vuetify.breakpoint.lgAndUp" v-bind="$attrs" v-on="$listeners">
    <me-list-item />
    <v-divider />
    <v-list dense>
      <v-list-item v-for="link in links" :key="link.name" :to="link.to" link exact v-show="link.editor ? editor : true">
        <v-list-item-icon>
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ link.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import MeListItem from '@/components/MeListItem';
import authMixin from '@/mixins/auth';

export default {
  name: 'NavigationDrawer',
  components: {
    MeListItem,
  },
  mixins: [authMixin],
  data() {
    return {
      links: [
        {
          name: 'Calendar',
          to: { name: 'Calendar' },
          icon: 'mdi-calendar',
        },
        {
          name: 'Import',
          to: { name: 'Import' },
          icon: 'mdi-calendar-import',
          editor: true,
        },
      ],
    };
  },
};
</script>
