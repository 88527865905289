import genesys from '@/axios/genesys';

export default {
  //General
  getUri(uri) {
    return genesys.get(uri);
  },
  getMe() {
    return genesys.get('/api/v2/users/me?expand=organization%2Cgroups%2Cauthorization');
  },
  searchUsers(body) {
    return genesys.post('/api/v2/users/search', body);
  },
  getDivisionsPermitted(permission) {
    return genesys.get(`/api/v2/authorization/divisionspermitted/paged/me?permission=${permission}&pageNumber=1&pageSize=50`);
  },
  getQueuesForUser(userId) {
    return genesys.get(`/api/v2/users/${userId}/queues?pageNumber=1&pageSize=500`);
  },
  getQueues(name) {
    return genesys.get(`/api/v2/routing/queues${name ? '?name=*' + name + '*' : ''}`);
  },
};
