<template>
  <v-btn outlined @click="logout()" v-if="loggedIn">Logout</v-btn>
</template>

<script>
import AuthService from '@/services/AuthService.js';
import { mapGetters } from 'vuex';

export default {
  name: 'LoginLogoutButton',
  methods: {
    async logout() {
      try {
        await AuthService.logout();
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch('auth/logout');
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['loggedIn']),
  },
};
</script>
