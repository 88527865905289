import GenesysService from '@/services/GenesysService.js';

export default {
  namespaced: true,
  state: {
    users: {
      result: {},
      loading: false,
    },
    options: null,
    filter: {
      status: [],
      division: [],
      search: '',
    },
  },
  mutations: {
    SET_USERS(state, data) {
      state.users.result = data;
    },
    SET_USERS_STATUS(state, status) {
      state.users.loading = status;
    },
    SET_OPTIONS(state, options) {
      state.options = options;
    },
    SET_FILTER(state, { prop, value }) {
      state.filter[prop] = value;
    },
    SET_QUEUES_FOR_USER(state, { queues, userId }) {
      state.users.result.results = state.users.result.results.map((user) => {
        if (user.id === userId) return { ...user, queues };
        return user;
      });
    },
  },
  actions: {
    searchUsers({ dispatch, commit }, body) {
      commit('SET_USERS_STATUS', true);
      GenesysService.searchUsers(body)
        .then((response) => commit('SET_USERS', response.data))
        .catch((error) => {
          console.error(error.message);
          dispatch('notifications/addNotification', { message: 'Failed to get users', type: 'error' }, { root: true });
        })
        .finally(() => commit('SET_USERS_STATUS', false));
    },
    updateOptions({ commit }, options) {
      commit('SET_OPTIONS', options);
    },
    updateFilter({ commit }, { prop, value }) {
      commit('SET_FILTER', { prop, value });
    },
    getQueuesForUser({ dispatch, commit }, user) {
      GenesysService.getQueuesForUser(user.id)
        .then((response) => commit('SET_QUEUES_FOR_USER', { queues: response.data.entities, userId: user.id }))
        .catch((error) => {
          console.error(error.message);
          dispatch('notifications/addNotification', { message: 'Failed to get queues for user', type: 'error' }, { root: true });
        });
    },
  },
};
